import React from 'react';
import styled from 'styled-components';
import PageContainer from '../Common/PageContainer';
import Spinner from '../../Infrastructure/Shared/Spinner';

const CenteredContainer = styled.div`
    text-align: center;
`;

const StyledSpinner = styled(Spinner)`
    color: #00afed !important;
    margin-top: 3rem;
`;

const FullPageLoader = () => (
    <PageContainer>
        <CenteredContainer>
            <StyledSpinner customSize={'la3x'} />
        </CenteredContainer>
    </PageContainer>
);

export default FullPageLoader;
