import styled from 'styled-components';

const Container = styled.div`
    min-height: 60vh;
    max-width: 1168px;
    margin: 64px auto 1rem;
    padding: 1rem;
`;

const PageContainer = ({ children }) => <Container>{children}</Container>;

export default PageContainer;
